<template>
  <v-card style="height: 100vh" class="rounded-drawer">
    <v-navigation-drawer
      style="height: 100%"
      v-model="drawer"
      :rail="rail"
      permanent
      @click="rail = false"
    >
      <v-list density="compact" nav style="height: 100%" :color="color1">
        <v-list-item
          ><v-img
            src="../../assets/images/logo_1.png"
            class="photo-icon"
          ></v-img
        ></v-list-item>
        <v-list-item title="Accueil" @click="showHome"
          ><v-img
            class="mx-3 mt-5"
            :style="{
              color: buttonTextValidColor,
              width: '40px',
              height: '40px',
              left: '10px',
            }"
            src="../../assets/images/menu_accueil.png"
            alt=""
        /></v-list-item>
        <v-list-item title="Porte-monnaie" @click="showWalletCharge"
          ><v-img
            class="mx-3 mt-5"
            :style="{
              color: buttonTextValidColor,
              width: '40px',
              height: '40px',
              left: '10px',
            }"
            src="../../assets/images/menu_wallet.png"
            alt=""
        /></v-list-item>
        <v-list-item
          title="Prise de photo en attentes"
          @click="showWaittingShootList"
          ><v-img
            class="mx-3 mt-5"
            :style="{
              color: buttonTextValidColor,
              width: '40px',
              height: '40px',
              left: '10px',
            }"
            src="../../assets/images/menu_suivi.png"
            alt=""
        /></v-list-item>
        <v-list-item title="Suive de commande" @click="showOrderList"
          ><v-img
            class="mx-3 mt-5"
            :style="{
              color: buttonTextValidColor,
              width: '40px',
              height: '40px',
              left: '10px',
            }"
            src="../../assets/images/menu_commande.png"
            alt=""
        /></v-list-item>
        <v-list-item title="Prendre une photo" @click="newShoot"
          ><v-img
            class="mx-3 mt-5"
            :style="{
              color: buttonTextValidColor,
              width: '40px',
              height: '40px',
              left: '10px',
            }"
            src="../../assets/images/menu_photo.png"
            alt=""
        /></v-list-item>
        <v-list-item title="Déconnexion" class="logout-item" @click="logout"
          ><v-img
            class="mx-3 mt-5"
            :style="{
              color: buttonTextValidColor,
              width: '40px',
              height: '40px',
              left: '10px',
            }"
            src="../../assets/images/menu_logout_1.png"
            alt=""
        /></v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  data() {
    return {
      isVisible: false,
      agenceUuid: null,
      agenceId: null,
      credit: 0,
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      drawer: true,
      rail: true,
    }
  },
  methods: {
    showHome() {
      this.$router.push({
        name: 'Home-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showWalletCharge() {
      this.$router.push({
        name: 'Wallet-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showWaittingShootList() {
      this.$router.push({
        name: 'Waiting-orders-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showOrderList() {
      this.$router.push({
        name: 'Orders-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    newShoot() {
      this.$router.push({
        name: 'New-order-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
          agence_id: this.agenceId,
          agence_uid: this.agenceUuid,
        },
      })
    },
    logout() {
      localStorageService.clearAll()
      this.$router.push({
        name: 'Authentication-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
  },
}
</script>
<style scoped>
.logout-item {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 50px;
}
.rounded-drawer {
  border-radius: 0 40px 40px 0 !important;
  width: 100px !important;
  width: 80px;
}
.photo-icon {
  margin: auto;
  margin-top: 40px;
  margin-left: 20px;
  width: 39.97px;
  height: 40px;
  margin-bottom: 40px;
}
</style>
