var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-drawer",staticStyle:{"height":"100vh"}},[_c('v-navigation-drawer',{staticStyle:{"height":"100%"},attrs:{"rail":_vm.rail,"permanent":""},on:{"click":function($event){_vm.rail = false}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticStyle:{"height":"100%"},attrs:{"density":"compact","nav":"","color":_vm.color1}},[_c('v-list-item',[_c('v-img',{staticClass:"photo-icon",attrs:{"src":require("../../assets/images/logo_1.png")}})],1),_c('v-list-item',{attrs:{"title":"Accueil"},on:{"click":_vm.showHome}},[_c('v-img',{staticClass:"mx-3 mt-5",style:({
            color: _vm.buttonTextValidColor,
            width: '40px',
            height: '40px',
            left: '10px',
          }),attrs:{"src":require("../../assets/images/menu_accueil.png"),"alt":""}})],1),_c('v-list-item',{attrs:{"title":"Porte-monnaie"},on:{"click":_vm.showWalletCharge}},[_c('v-img',{staticClass:"mx-3 mt-5",style:({
            color: _vm.buttonTextValidColor,
            width: '40px',
            height: '40px',
            left: '10px',
          }),attrs:{"src":require("../../assets/images/menu_wallet.png"),"alt":""}})],1),_c('v-list-item',{attrs:{"title":"Prise de photo en attentes"},on:{"click":_vm.showWaittingShootList}},[_c('v-img',{staticClass:"mx-3 mt-5",style:({
            color: _vm.buttonTextValidColor,
            width: '40px',
            height: '40px',
            left: '10px',
          }),attrs:{"src":require("../../assets/images/menu_suivi.png"),"alt":""}})],1),_c('v-list-item',{attrs:{"title":"Suive de commande"},on:{"click":_vm.showOrderList}},[_c('v-img',{staticClass:"mx-3 mt-5",style:({
            color: _vm.buttonTextValidColor,
            width: '40px',
            height: '40px',
            left: '10px',
          }),attrs:{"src":require("../../assets/images/menu_commande.png"),"alt":""}})],1),_c('v-list-item',{attrs:{"title":"Prendre une photo"},on:{"click":_vm.newShoot}},[_c('v-img',{staticClass:"mx-3 mt-5",style:({
            color: _vm.buttonTextValidColor,
            width: '40px',
            height: '40px',
            left: '10px',
          }),attrs:{"src":require("../../assets/images/menu_photo.png"),"alt":""}})],1),_c('v-list-item',{staticClass:"logout-item",attrs:{"title":"Déconnexion"},on:{"click":_vm.logout}},[_c('v-img',{staticClass:"mx-3 mt-5",style:({
            color: _vm.buttonTextValidColor,
            width: '40px',
            height: '40px',
            left: '10px',
          }),attrs:{"src":require("../../assets/images/menu_logout_1.png"),"alt":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }